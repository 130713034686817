import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs';
import {
  AskAssistant,
  ClearChat,
  SetChatWindowVisibility,
} from '../data/actions/chatbot.action';
import { AppState } from '../data/state/app.state.model';
import { IChatbotMessage } from '../data/state/chatbot.state.model';
import { AuthService } from './auth.service';
import { UserSubscriptionService } from './user-subscriptions.service';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  CHATBOT_FEATURE_KEY = 'chatbot';
  PREMIUM_USER_KEY = 'premiumUser';

  messages = this.store$
    .select((state) => state.coPilot.conversation.data.messages)
    .pipe(
      map((messages) =>
        messages.map((m) => ({
          ...m,
          content: this.domSanitizer.sanitize(
            SecurityContext.HTML,
            m.content
          ) as string,
        }))
      )
    );

  loading = this.store$.select(
    (state) => state.coPilot.conversation.status === 'loading'
  );

  chatWindowCurrentVisibility = this.store$.select(
    (state) => state.coPilot.conversation.windowOpened
  );

  chatIntroText = this.userSubscriptionService.activeSubscription$.pipe(
    map((sub) => {
      const features = sub?.appConfiguration?.features;

      if (features && Array.isArray(features)) {
        const chatBoxFeature = features.find(
          (feature) => feature.key === this.CHATBOT_FEATURE_KEY
        );
        return chatBoxFeature?.introText;
      }
    })
  );
  featureEnabled$ = this.authService.userRoles$.pipe(
    withLatestFrom(this.userSubscriptionService.activeSubscription$),
    map(([userRoles, activeSubscription]) => {
      const nativeCastRoles: string[] = userRoles || [];
      const isPremiumUser = nativeCastRoles.includes(this.PREMIUM_USER_KEY);

      const chatBoxFeature =
        activeSubscription?.appConfiguration?.features?.find(
          (feature) => feature.key === this.CHATBOT_FEATURE_KEY
        );

      return isPremiumUser && chatBoxFeature?.enabled === true;
    })
  );

  constructor(
    private store$: Store<AppState>,
    private userSubscriptionService: UserSubscriptionService,
    private domSanitizer: DomSanitizer,
    private authService: AuthService
  ) {}

  clearChat() {
    this.store$.dispatch(new ClearChat());
  }

  sendUserMessage(userMessage: string) {
    if (!userMessage) {
      return;
    }
    const message: IChatbotMessage = { role: 'user', content: userMessage };
    this.store$.dispatch(new AskAssistant(message));
  }

  setChatWindowVisibility(opened: boolean) {
    this.store$.dispatch(new SetChatWindowVisibility(opened));
  }
}
